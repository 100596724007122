import React, { useState } from "react";
import {
  AppBar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Typography
} from "@mui/material";
import { Link } from "react-router-dom";
import ComputerIcon from "@mui/icons-material/Computer";
import MemoryIcon from "@mui/icons-material/Memory";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import appIcon from "../images/app-icon.png";
import WindowIcon from "@mui/icons-material/Window";

const LandingPage = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.setItem("token", "");
    navigate("/");
   
  };
  const open = Boolean(anchorEl);

  //common styles for the icons to avoid repitition
  const iconStyle = {
    fontSize: "40px",
    width: "150px",
    height: "150px",
    color: "black"
  };

  // Array of objects for dynamic rendering
  const gridItems = [
    {
      name: "Virtual Machines",
      route: "/vms",
      icon: <ComputerIcon style={iconStyle} />
    },
    {
      name: "Hardwares",
      route: "/hardwares",
      icon: <MemoryIcon style={iconStyle} />
    },
    {
      name: "Softwares",
      route: "/softwares",
      icon: <WindowIcon style={iconStyle} />
    }
  ];
  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            <Paper
              elevation={3}
              style={{ height: 80, width: 80, margin: 10, padding: 0 }}
            >
              <img style={{ height: 80, width: 80 }} src={appIcon} alt="App Icon" />
            </Paper>
          </Typography>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Resource Manager
          </Typography>
          <IconButton
            style={{ marginRight: "16px" }}
            edge="end"
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={handleMenuOpen}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleMenuClose}
           
          >
            <MenuItem onClick={handleLogout}>Log out</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {gridItems.map((item, index) => (
          <Grid item key={index}>
            <Link to={item.route} style={{ textDecoration: "none" }}>
              <Paper
                variant="outlined"
                style={{
                  padding: "20px",
                  textAlign: "center",
                  width: "200px",
                  height: "200px",
                  marginTop: "20px",
                  border: "3px solid black",
                  cursor: "pointer"
                }}
              >
                {item.icon}
                <Typography variant="subtitle1">{item.name}</Typography>
              </Paper>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default LandingPage;