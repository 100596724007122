import "./App.css";
import LoginScreen from "./auth/login";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import ResourceType from "./resource-request/resource-type";
import ProtectedRoutes from "./ProtectedRoutes";
import LandingPage from "./resource-request/landing-page";
import Softwares from "./resource-request/softwares-page";
import Hardwares from "./resource-request/hardwares-page";

function App() {
  //set LogingScreen as the default page
  return (
    <Router>
      <Routes>
        <Route path="/" Component={LoginScreen} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/home" Component={LandingPage} />
          <Route path="/vms" Component={ResourceType} />
          <Route path="/softwares" Component={Softwares} />
          <Route path="/hardwares" Component={Hardwares} />

        </Route>
      </Routes>
      {/* Other routes can go here */}
    </Router>
  );
}

export default App;
