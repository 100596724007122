import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  ListItemAvatar,
  Avatar,
  CircularProgress,
  Stack,
  AppBar,
  Toolbar,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Grid,
  Chip,
  Icon,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import CircleIcon from "@mui/icons-material/Circle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LanIcon from "@mui/icons-material/Lan";
import { useNavigate } from "react-router-dom";
import appIcon from "../images/app-icon.png";

const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.setItem("token", "");
    navigate("/");
  };
  const open = Boolean(anchorEl);

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          <Paper
            elevation={3}
            style={{ height: 80, width: 80, margin: 10, padding: 0 }}
          >
            <img style={{ height: 80, width: 80 }} src={appIcon} />
          </Paper>
        </Typography>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Resource Manager
        </Typography>
        <IconButton
          style={{ marginRight: "16px" }}
          edge="end"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={handleMenuOpen}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleLogout}>Log out</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

interface Item {
  vmId: string;
  vmName: string;
  vmSize: string;
  vmLocation: string;
  vmStatus: string;
  vmOsType: string;
  ipAddress: string;
  user: string;
}

interface State {
  items: Item[];
  isLoading: boolean;
  isDrawerOpen: boolean;
  selectedItem: Item | null;
  isDialogOpen: boolean;
}

class ResourceType extends React.Component<{}, State> {
  interval: any;
  constructor(props: {}) {
    super(props);

    this.state = {
      items: [],
      isLoading: true,
      isDrawerOpen: false,
      selectedItem: null,
      isDialogOpen: false,
    };
  }

  componentDidMount() {
    this.getAllVms();
    this.interval = setInterval(
      this.getAllVms,
      10000
    )
  }


  handleOpen = (item: Item) => {
    this.setState({ isDrawerOpen: true, selectedItem: item });
  };

  handleClose = () => {
    this.setState({ isDrawerOpen: false, selectedItem: null });
  };

  handleStart = (selectedItem: Item | null) => {
    this.setState({ isLoading: true });
    this.handleClose();
    fetch(
      `${process.env.REACT_APP_HOST_URL}resource/vm/${selectedItem?.vmName}/start`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then(() => this.getAllVms())
      .catch((error) => {
        this.setState({ isLoading: false });
        console.error("Error:", error);
      });
  };

  handleStop = (selectedItem: Item | null) => {
    this.handleClose();
    this.setState({ isDialogOpen: false });
    fetch(
      `${process.env.REACT_APP_HOST_URL}resource/vm/${selectedItem?.vmName}/stop`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then(() => this.getAllVms())
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getAllVms = () => {
    fetch(`${process.env.REACT_APP_HOST_URL}resource/vm`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      if (response.status === 401) {
        // Unauthorized, log out the user
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to base route
        return; // Stop further processing
      }
      return response.json();
    })
      .then((data: Item[]) => {
        
        let status = this.checkVmStatus(data);
        this.setState({ items: data, isLoading: false });
        if (status) {
          if (this.interval) {
            clearInterval(this.interval);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ isLoading: false });
      });
  };
  checkVmStatus(data: Item[]) {
    for (let element of data) {
      if (
        !element.hasOwnProperty("vmStatus") ||
        (element.vmStatus !== "running" && element.vmStatus !== "deallocated")
      ) {
        return false;
      }
    }
    return true;
  }

  render() {
    const { items, isLoading, isDrawerOpen, selectedItem, isDialogOpen } =
      this.state;
    if (isLoading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Stack sx={{ color: "grey.500" }} spacing={4} direction="row">
            <CircularProgress color="secondary" />
            <CircularProgress color="success" />
            <CircularProgress color="inherit" />
          </Stack>
        </div>
      );
    }
    // Group items by status
    const groups: { [key: string]: Item[] } = {};
    items.forEach((item) => {
      if (!groups[item.vmStatus]) {
        groups[item.vmStatus] = [];
      }
      groups[item.vmStatus].push(item);
    });

    return (
      <div>
        <NavBar />
        <Box m={2}>
          {Object.entries(groups).map(([status, items]) => (
            <Accordion key={status} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{status}</Typography>
                <div style={{ marginLeft: "5px" }}>
                  {status === "VM running" ? (
                    <CircleIcon style={{ color: "green" }} />
                  ) : status === "VM deallocated" ? (
                    <CircleIcon style={{ color: "red" }} />
                  ) : (
                    <CircleIcon style={{ color: "orange" }} />
                  )}
                </div>
              </AccordionSummary>

              <AccordionDetails>
                <List sx={{ width: "100%", bgcolor: "white" }}>
                  {items.map((item) => (
                    <Paper
                      elevation={0}
                      key={item.vmId}
                      onClick={() => this.handleOpen(item)}
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <InboxIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.vmName}
                          secondary={item.vmStatus}
                        />
                        {item.vmStatus === "VM running" ? (
                          <CircleIcon style={{ color: "green" }} />
                        ) : item.vmStatus === "VM deallocated" ? (
                          <CircleIcon style={{ color: "red" }} />
                        ) : (
                          <CircleIcon style={{ color: "orange" }} />
                        )}
                      </ListItem>
                    </Paper>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <Drawer anchor="bottom" open={isDrawerOpen} onClose={this.handleClose}>
          <Box sx={{ my: 3, mx: 2 }}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography gutterBottom variant="h4" component="div">
                  <InboxIcon />
                  {selectedItem ? (
                    <label>&nbsp;{selectedItem.vmName}</label>
                  ) : null}
                </Typography>
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h6" component="div">
                  {selectedItem?.vmStatus === "VM running" ? (
                    <CircleIcon color="success" />
                  ) : (
                    <CircleIcon color="error" />
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Typography color="text.secondary" variant="body2">
              <Stack direction="row" spacing={1}>
                <Chip
                  color="success"
                  variant="outlined"
                  icon={<LocationOnIcon />}
                  label={
                    selectedItem ? (
                      <label>&nbsp;{selectedItem.vmLocation}</label>
                    ) : null
                  }
                />
                <Chip
                  color="success"
                  variant="outlined"
                  icon={<AppSettingsAltIcon />}
                  label={
                    selectedItem ? (
                      <label>&nbsp;{selectedItem.vmOsType}</label>
                    ) : null
                  }
                />
                <Chip
                  color="success"
                  variant="outlined"
                  icon={<FormatSizeIcon />}
                  label={
                    selectedItem ? (
                      <label>&nbsp;{selectedItem.vmSize}</label>
                    ) : null
                  }
                />
              </Stack>
            </Typography>
          </Box>
          <Divider variant="middle" />

          <Typography
            color="text.secondary"
            variant="body2"
            style={{ margin: "10px" }}
          >
            <Stack direction="row" spacing={1}>
              <Chip
                color="primary"
                icon={<LanIcon />}
                label={
                  selectedItem ? (
                    <label>&nbsp;{selectedItem.ipAddress}</label>
                  ) : null
                }
              />
              <Chip
                icon={<AccountCircleIcon />}
                label={
                  selectedItem ? <label>&nbsp;{selectedItem.user}</label> : null
                }
              />
            </Stack>
          </Typography>
          <Divider variant="middle" />
          <Stack direction="row" spacing={1} style={{ margin: "10px" }}>
            {selectedItem?.vmStatus === "VM running" ? (
              <Button
                variant="outlined"
                color="error"
                onClick={() => this.setState({ isDialogOpen: true })}
              >
                Stop
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  this.handleStart(selectedItem);
                }}
              >
                Start
              </Button>
            )}
          </Stack>
          <Dialog
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            open={isDialogOpen}
            onClose={() => this.setState({ isDialogOpen: false })}
          >
            <DialogTitle id="dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <DialogContentText id="dialog-description">
                Are you sure you want to stop the Virtual Machine from running
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ isDialogOpen: false })}>
                Cancel
              </Button>
              <Button onClick={() => this.handleStop(selectedItem)}>Yes</Button>
            </DialogActions>
          </Dialog>
        </Drawer>
      </div>
    );
  }
}

export default ResourceType;
