import React, { useState, FormEvent, useEffect } from "react";
import {
  Button,
  Box,
  TextField,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
  Container,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import CheckIcon from "@mui/icons-material/Check";
import image from "../images/bgtreeimg.jpg";
import bgimage from "../images/backimg.jpg";
import iconImage from "../images/app-icon.png";

const LoginScreen: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isUsernameValid, setIsUsernameValid] = useState(false);

  const [error, setError] = useState("");

  const validateUsername = (value: string) => {
    const usernameRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsUsernameValid(usernameRegex.test(value));
    setUsername(value);
  };

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const apiHostUrl = process.env.REACT_APP_HOST_URL; //Get the base URL of the API from the environment variable.
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.preventDefault();
    
    setIsLoading(true);

    try {
      const response = await axios.post(`${apiHostUrl}auth/login`, {
        username,
        password,
      });

      const token = response.data.token;
      // Save the token somewhere secure like cookies, localStorage, or in-memory
      // depending on your security requirements
      localStorage.setItem("token", token);

      setIsLoading(false);
      navigate("/home");
      // Redirect or do something after successful login
    } catch (error) {
      setIsLoading(false);
      // Handle the error accordingly
      setError("Invalid credentials");
      console.error("Login failed", error);
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Redirect to home page
      navigate("/home");
    }
  }, []);

  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        backgroundImage: `url(${bgimage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center ",
        minWidth: "100vw",
      }}
    >
      <Paper
        sx={{
          height: "70vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "16px",
          width: "400px",
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          backgroundPosition: "center ",
        }}
      >
        <img src={iconImage} style={{ width: "120px" }} alt="Icon" />

        <form
          noValidate
          onSubmit={handleSubmit}
          style={{ width: "100%", marginTop: "-20px" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TextField
              variant="standard"
              margin="normal"
              required
              id="username"
              name="username"
              placeholder="Username"
              value={username}
              onChange={(e) => {validateUsername(e.target.value) ; setError('')}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
                endAdornment: isUsernameValid ? (
                  <InputAdornment position="end">
                    <IconButton edge="end" color="success">
                      <CheckIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
              sx={{ maxWidth: "300px", width: "100%", mb: "30px" }}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              name="password"
              placeholder="Your Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => {setPassword(e.target.value) ; setError('')}}

              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ maxWidth: "300px", width: "100%", mb: "30px" }}
            />
            {error && (
              <Typography
                variant="body2"
                color="error"
                sx={{ marginBottom: "10px" }}
              >
                {error}
              </Typography>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              sx={{
                borderRadius: "999em",
                textTransform: "capitalize",
                height: "40px",
                width: "150px",
                margin: "auto",
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="primary" />
              ) : (
                "Sign In"
              )}
            </Button>

       
          </Box>
        </form>
      </Paper>
    </Container>
  );
};
export default LoginScreen;
