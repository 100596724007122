import React, { useState } from "react";
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Typography
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import appIcon from "../images/app-icon.png";

const Softwares = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.setItem("token", "");
    navigate("/");
   
  };
  const open = Boolean(anchorEl);


  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            <Paper
              elevation={3}
              style={{ height: 80, width: 80, margin: 10, padding: 0 }}
            >
              <img style={{ height: 80, width: 80 }} src={appIcon} alt="App Icon" />
            </Paper>
          </Typography>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Resource Manager
          </Typography>
          <IconButton
            style={{ marginRight: "16px" }}
            edge="end"
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={handleMenuOpen}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleMenuClose}
           
          >
            <MenuItem onClick={handleLogout}>Log out</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      
      <Typography variant="h3" align="center" style={{ marginTop: "20px" }}>
        Feature coming soon
      </Typography>
     
    </div>
  );
};

export default Softwares;